export default {
  components: {
    atoms: {
      atomSelect: {
        noOptions: "該当する選択肢がありません",
      },
    },
    molecules: {
      dataTable: {
        sampleSelect: "表示件数",
        outofNumberTotalBefore: "全",
        outofNumberTotalAfter: "件",
        perPageOptions: "件",
      },
      requiredMark: {
        required: "必須",
      },
    },
    organisms: {
      completedModal: {
        backToIndex: "一覧に戻る",
        modalTitle: "完了",
        modalMessage: "完了しました",
      },
      confirmModal: {
        modalTitle: "確認",
        modalOkButtonLabel: "はい",
        modalCancelButtonLabel: "いいえ",
      },
      errorModal: {
        modalTitle: "エラー",
        modalButtonLabel: "一覧に戻る",
        showMessage: "エラーが発生しました",
      },
      privacyMark: {
        privacyMark: "ピースマインドはプライバシーマークを取得しています。",
        isms: "ピースマインドは銀座本社の社員支援コンサルティング部・SC運用推進部・情報システム部（情報セキュリティ委員会）で情報セキュリティマネジメントシステム(ISO/IEC27001)を取得しています。",
        ssl: "送信される情報は、SSLというセキュリティシステムによって暗号化され、保護されております。",
      },
      userFooter: {
        environment: "動作環境について",
        personalInformation: "個人情報の取扱いについて",
        informationSecurity: "情報セキュリティ基本方針",
        termsOfUse: "利用規約",
        dataUtilizationAgreement: "データ活用の同意",
        administrator: "運営者",
        inquiry: "お問い合わせ",
      },
      userHeader: {
        memberToRegister: "会員登録",
        login: "ログイン",
        changeProfile: "プロフィール変更",
        changePassword: "アカウントセキュリティ",
        changeMailAddress: "メールアドレス変更",
        registerSecretQuestion: "秘密の質問設定",
        changeMailRecieving: "メール受信設定",
        logout: "ログアウト"
      },
      userNavigation: {
        eap: "カウンセリング",
        cunselingApply: "相談申込",
        counselingHistory: "相談履歴",
        eapSelfCare: "セルフケア",
        eapSelfCheck: "セルフチェック",
        stressCheck: "ストレスチェック",
        stressCheckTestHistory: "受検結果履歴",
        languageSetting: "言語設定",
        selfCareCheck: "セルフケアチェック",
        webLearning: "セルフラーニング",
        column: "コラム",
        monthlyTopics: "今月のトピックス",
        mySupplement: "わたしのサプリメント",
        workplaceSupplement: "職場のサプリメント",
        userGuide: "ユーザーズガイド",
        howToUseThisPlatform: "利用案内",
        faq: "よくあるご質問",
        admin: "管理画面",
      },
    },
  },
  plugins: {
    veeValidate: {
      required: "必須項目",
      requiredSelect: "選択してください",
      min: "{min}文字以上にしてください",
      max: "{max}文字以下にしてください",
      digits: "{digits}文字で入力してください",
      email: "メールアドレス形式の必要があります",
      regex: "半角英数字記号で入力してください",
      passwordCharacterTypes:
        '半角英大文字、英小文字、数字、記号（ハイフン"-"、アンダーバー"_"のみ）のうち3種類以上を利用してください',
      numeric: "数値のみ",
      integer: "半角数字で入力してください",
      max_value: "上限は{maxValue}",
      between: "{min}〜{max}まで",
      numberIsSmaller: "{number}以下で入力してください",
      match: "{match}を一致させてください",
    },
  },
  views: {
    login: {
      account: {
        groupLogin: "団体ログイン",
        groupId: "団体ID",
        password: "パスワード",
        loginFailure: "ログイン認証に失敗しました。",
        login: "ログイン",
      },
      group: {
        login: {
          groupLogin: "グループ企業ログイン",
          groupId: "グループ企業ID",
          loginFailure: "ログイン認証に失敗しました。",
          login: "ログイン",
        },
        suppliers: {
          title: "所属先を選択",
          groupId: "グループ企業ID:",
        },
      },
      entrance: {
        honorificTitle: "様",
        departmentSelect: "所属を選択してください。",
      },
      user: {
        login: "ログイン",
        useService: "利用サービス",
        stressCheck: {
          name: "ストレスチェック",
          loginId: "個人ID",
        },
        group: {
          name: "カウンセリング",
          loginId: "メールアドレス",
        },
        eap: {
          name: "カウンセリング",
          loginId: "メールアドレス",
        },
        useServiceAnnotation:
          "※ストレスチェック情報連携に同意されている場合、どちらのサービスも利用可能となります。",
        password: "パスワード",
        passwordReset: "パスワードを忘れた方はこちら",
        userSelectModal: {
          modalTitle: "ユーザーの選択",
          modalDescription: "ログインするユーザーを選択してください。",
          modalLoginId: "個人ID",
          modalFullName: "氏名",
        },
        noUserModal: {
          modalTitle: "エラー",
          modalDescription:
            "ユーザーが見つかりませんでした。貴社の管理者にお問い合わせください。",
          modalButtonLabel: "閉じる",
        },
        timeoutModal: {
          modalTitle: "エラー",
          modalDescription: "タイムアウトしました。再度お試しください。",
          modalButtonLabel: "閉じる",
        },
      },
    },
    counselings: {
      index: {
        title: "相談履歴",
        hierarchy: "相談履歴",
        onlineCounselingLink: {
          1: "オンラインカウンセリングの履歴は",
          2: "こちら",
          3: "から",
        },
      },
      history: {
        title: "相談履歴",
        description:
          "電話などでのご予約も含めた実施済み・実施予定の相談の一覧を表示しています。",
        table: {
          sessionDate: "実施日",
          counseling: "カウンセリング",
          counselingDetail: "相談詳細",
          canceled: "（キャンセル済）",
        },
      },
      detail: {
        title: "相談履歴詳細",
        hierarchy: "相談履歴詳細",
        counselingDetail: "相談履歴",
        serviceClassification: "相談種別",
        channel: "チャネル",
        counselingDate: "実施日",
        notImplemented: "日程調整中",
        reverseButton: "戻る",
      },
      applicationList: {
        title: "相談申込履歴",
        description:
          "Web経由での過去の相談のお申込みの履歴を一覧表示しています。",
        table: {
          counselingApplyDate: "申込日",
          counseling: "カウンセリング",
          counselingDetail: "申込詳細",
        },
        reverseButton: "トップに戻る",
      },
      applicationDetail: {
        title: "相談申込履歴詳細",
        hierarchy: "相談申込履歴詳細",
        applicationDetail: "申込内容",
        serviceClassification: "相談種別",
        channel: "チャネル",
        counselingApplyDate: "申込日",
        fullName: "氏名",
        nameFurigana: "氏名ふりがな",
        birthday: "生年月日",
        sex: "性別",
        phoneNumber: "携帯電話",
        mailAddress: "メールアドレス",
        employmentStatus: "雇用形態",
        prefectures: "お住まいの都道府県",
        trigger: "ご利用のきっかけ",
        triggerDetail: "ご利用のきっかけ(詳細)",
        desiredDateTime: "希望日時",
        monday: "月曜日",
        tuseday: "火曜日",
        wednesday: "水曜日",
        thursday: "木曜日",
        friday: "金曜日",
        saturday: "土曜日",
        desiredPlace: "希望実施場所",
        desiredOtherPlace: "その他の希望実施場所：",
        counselingContent: "相談内容",
        outpatientTakeMedication: "通院・服薬の有無",
        counselingByReferral: "紹介での相談ですか？",
        reverseButton: "戻る",
      },
      apply: {
        hierarchy: "相談申込",
        counselingType: {
          hierarchy: "相談種別選択",
          title: "相談種別選択",
          services: "お客様がご利用いただけるサービスと利用回数",
          counselingCategory: "カウンセリング",
          counselingCategoryStatement:
            "ご自身のお悩みやマネージメントに関するご相談はこちら。",
          coachingCategory: "ウェルネスコーチング",
          coachingCategoryStatement:
            "禁煙やダイエットなど生活習慣の変化に取り組まれたい方はこちら。",
          noChoiceStatement:
            "相談回数が上限に達しているため、お申込頂けません。",
          servicesStatement:
            "下記にご契約内容に応じたご利用いただけるサービスと利用回数を表示しております。利用回数以内でしたら何度でもご利用いただけますので、ご希望に応じて是非ご利用ください。",
          counselingType: "相談種別",
          maxCounselingCount: "相談回数の上限",
          remainingSessionCount: "残りの相談可能回数",
          remainingCount: "残りの相談可能回数",
          voluntaryCounseling: "自主相談",
          voluntaryCounselingStatement:
            "ご自身のお悩みについてカウンセリングを利用したい場合はこちら。",
          encouragedCounseling: "紹介相談",
          encouragedCounselingStatement:
            "上司・人事などからカウンセリングの利用を勧められた方はこちら。",
          managementCounseling: "人事・管理職相談",
          managementCounselingStatement:
            "人事・管理職・産業保健スタッフの方で、従業員や部下について相談したい場合はこちら。",
          wellnessCoachingVoluntary: "自主相談",
          wellnessCoachingVoluntaryStatement:
            "ご自身のお悩みについて利用したい場合はこちら。",
          wellnessCoachingEncouraged: "紹介相談",
          wellnessCoachingEncouragedStatement:
            "上司・人事などから利用を勧められた方はこちら。",
          onlineCounseling: "オンラインカウンセリング",
          onlineCounselingStatement: "テキストによる相談がしたい方はこちら",
          counselingNow: "今すぐご相談したい方はこちら",
          directPhoneCounseling: "直通電話相談: ",
          directPhoneCounselingNote:
            `※1回につき最大30分のメンタルヘルス相談ができます。<br />
            ※特定のカウンセラーを指名、あるいは変更することはできません。<br />
            ※一回完結の相談を前提としており、同一内容について複数回に渡り相談する窓口ではありません。<br />
            ※上記仕様を越える利用を希望される場合、心理専門職による判断を行った上で、適切なサービス利用を推奨したり、利用を制限する場合があります。<br />
            ※ご相談が混み合っている場合は、折り返しのご提案、またはおかけ直しをご依頼する場合がございます。ご了承下さい。`,
          telephoneCounselingTime: "月～土：9時～21時（年末年始・祝日除く）",
          telephoneCounselingTimeWith24Option: "24時間 365日 相談可能",
          workLifeCategory: "日常生活に関する相談（スマート・ワークライフ）",
          workLifeCounseling: "ワークライフ相談: ",
          workLifeStatement: {
            1: "子育てや介護など従業員の皆様が抱える様々なプライベートな課題に対して、情報提供を通じて早期解決をサポートする相談窓口です。",
            2: "日常生活において必要なあらゆる情報の提供を行っていますので、どうしたら良いか分からず困っている、こんな情報が欲しい、と思われたら、先ずご連絡ください。",
          },
          childCareInfo: {
            title: "●子育てに関する情報",
            statement: {
              1: "・子育て",
              2: "・妊娠、不妊治療",
              3: "・産休、育休、出産準備",
              4: "・子どもの発達、教育等",
              5: "",
              6: "",
            },
          },
          elderCareInfo: {
            title: "●高齢者に関する情報",
            statement: {
              1: "・高齢者のケア",
              2: "・住環境の選択",
              3: "・地域のサービス資源",
              4: "・老人ホーム、デイケア",
              5: "・家事代行等",
            },
          },
          dailyLivingInfo: {
            title: "●海外生活に関する情報",
            statement: {
              1: "・海外生活",
              2: "・海外での住環境",
              3: "・教育",
              4: "・医療情報等",
            },
          },
          workLifeNotes: {
            title: "＜ご利用上の注意＞",
            statement: {
              1: "・相談した内容がご本人の許可なしに第三者に公表されることは一切ありません。相談の秘密は厳守されますので、安心してご利用ください。",
              2: `・本サービスは、プライベートな課題（子育て、介護、生活一般など）に対する支援として提供される情報提供サービスです。
                    <br />
                    医師法、保健師助産師看護師法、その他の適用法令、または所轄官公庁の指導・指針・解釈等に抵触する行為はこれを行わないものとします。`,
              3: "・利用者に提供される情報は、該当資源の名前、連絡先、提供されるサービスの種類や料金等の情報が含まれます。",
              4: "・紹介された外部サービスの利用に関する料金は利用者の自己負担となります。",
            },
          },
          count: "回",
          endless: "無制限",
        },
        channel: {
          apply: "の申込み",
          faceToFace: "対面カウンセリング",
          faceToFaceStatement:
            "カウンセラーとの言葉のやり取りを通じて悩みや不安とじっくり向き合うことで、共に解決に向けて歩んでいきます。ピースマインドでは、交通の便がよく、どなたでも利用しやすい落ち着いた雰囲気のカウンセリングルームをご用意しております。",
          faceToFaceOnline: "オンライン対面カウンセリング",
          faceToFaceOnlineStatement:
            "ビデオ電話サービス（Zoom）を利用して、ご都合のよい時間・場所でカウンセリングを受けることができます。オンラインでありながら、カウンセラーの顔が見える対面に近い雰囲気でご利用いただけます。",
          telephone: "電話カウンセリング",
          telephoneStatement:
            "自宅の電話や携帯電話など、あなたが普段使われている電話で、ご都合のよい時間・場所でカウンセリングを受けることができます。特別な機器を使用することなく、お手軽にご利用いただけます。",
          faceToFaceWellness: "対面",
          faceToFaceStatementWellness:
            "カウンセラーとの対面でのセッションとなります。ピースマインドでは、交通の便がよく、どなたでも利用しやすい落ち着いた雰囲気のカウンセリングルームをご用意しております。",
          faceToFaceOnlineWellness: "オンライン対面",
          faceToFaceOnlineStatementWellness:
            "ビデオ電話サービス（Zoom）を利用して、ご都合のよい時間・場所でウェルネスコーチングを受けることができます。オンラインでありながら、カウンセラーの顔が見える対面に近い雰囲気でご利用いただけます。",
          telephoneWellness: "電話",
          telephoneStatementWellness:
            "自宅の電話や携帯電話など、あなたが普段使われている電話で、ご都合のよい時間・場所でウェルネスコーチングを受けることができます。特別な機器を使用することなく、お手軽にご利用いただけます。",
          telephoneCounseling: "お電話での予約はこちら",
          telephoneCounselingNow: "今すぐご相談したい方はこちら",
          telephoneCounselingTime: "月～土：9時～21時（年末年始・祝日除く）",
          telephoneCounselingTimeNow:
            "月～金：９時～18時（年末年始・祝日除く）",
          aboutCounselor: "カウンセラーについて",
          aboutCounselorStatement: `ピースマインドでは、ご利用者からの様々なご相談に対応できるよう、経験豊富で専門性の高いカウンセラーを配置しております。
                                      <br />
                                      臨床心理士、精神保健福祉士、シニア産業カウンセラーを中心として、臨床経験だけでなく、企業経営、管理職経験を持つカウンセラーを揃え、企業に勤める方々が抱えるお悩み・ご相談内容に精通した対応ができる体制となっております。
                                      <br />
                                      もちろん、職場に関するご相談だけでなく、家族問題、教育問題、その他プライベートで起こる様々なご相談についてもお受けできる体制を整えております。
                                      <br />
                                      また、当社の品質基準を設け、常に品質のチェックを行うことで、クオリティ維持向上の取組みを継続しておりますので、安心してご利用下さい。
                                      <br />
                                      担当カウンセラーのアサインに関しては、上記のようなカウンセラーの中から、利用者様のご相談内容に適したカウンセラーを専門チームがアサインさせていただきます。`,
          counselingRoom: "カウンセリングルームのご案内",
          ginzaRoom: "銀座ルーム",
          ginzaRoomAccess: `■アクセス<br />
                            ・都営浅草線「東銀座駅」 A8出口より徒歩1分<br />
                            ・東京メトロ日比谷線「東銀座駅」 A2出口より徒歩3分<br />
                            ・東京メトロ銀座線、丸の内線「銀座駅」 A12出口より徒歩3分<br />
                            ・東京メトロ有楽町線「銀座1丁目駅」 11番出口より徒歩3分<br />
                            ・JR有楽町駅より徒歩7分`,
          ginzaRoomAddress: `■住所<br />
                            ・〒104-0061<br />
                            ・東京都中央区銀座3-10-6 マルイト銀座第3ビル8F`,
          osakaOffice: "大阪オフィス",
          osakaOfficeAccess: `■アクセス<br />
                              ・地下鉄御堂筋線「淀屋橋駅」 13番出口より徒歩5分<br />
                              ・地下鉄御堂筋線「本町駅」 2番出口より徒歩5分`,
          osakaOfficeAddress: `■住所<br />
                              ・〒541-0047<br />
                              ・大阪府大阪市中央区淡路町4-4-11 アーバネックス淡路町ビル8F`,
          affiliatedHospital: "医療機関・専門機関のご案内について",
          affiliatedHospitalStatement:
            "ピースマインドでは、利用者様のご相談内容に応じて全国の医療機関・専門機関の情報をご案内しております。詳細はサポートセンター（0120-741-489）までお問い合わせください。",
          back: "戻る",
        },
        application: {
          hierarchyFaceToFace: "対面カウンセリング",
          hierarchyFaceToFaceOnline: "オンライン対面カウンセリング",
          hierarchyTelephone: "予約制電話カウンセリング",
          title: "申込",
          triggerLevel1: "ご利用のきっかけ",
          triggerLevel1Placeholder: "選択して下さい",
          otherTriggerLevel1Placeholder: "その他の場合に入力してください",
          triggerLevel2: "ご利用のきっかけ(詳細)",
          triggerLevel2Placeholder: "選択して下さい",
          otherTriggerLevel2Placeholder: "その他の場合に入力してください",
          preferredDate: "希望日時",
          monday: "月曜日",
          tuseday: "火曜日",
          wednesday: "水曜日",
          thursday: "木曜日",
          friday: "金曜日",
          saturday: "土曜日",
          "10_14": "10:00 ~ 14:00",
          "14_18": "14:00 ~ 18:00",
          "18_21": "18:00 ~ 21:00",
          preferredPlace: "希望実施場所",
          ginza: "銀座",
          nishishinjuku: "西新宿",
          tachikawa: "立川",
          kannai: "関内",
          osaka: "大阪",
          otherPlace: "その他",
          otherPlacePlaceholder: "その他ご希望の実施場所をご記載ください",
          description: "相談内容",
          descriptionPlaceholder: "相談したいことの概要をお書きください。",
          attendClinicAndMedicine: "通院・服薬の有無",
          attendClinicAndMedicinePlaceholder:
            "通院・服薬の有無を記載してください。通院・服薬がある場合は可能な範囲で詳細をご記載ください。",
          caution:
            "※心療内科・精神科に通院中の方は、カウンセリングのご利用について主治医にご相談のうえお申し込みください。",
          userPolicyStatement: {
            1: "当社の",
            2: "に同意する",
          },
          userPolicy: {
            1: "利用規約",
            2: "個人情報の取扱いについて",
          },
          confirm: "確認",
          back: "戻る",
          confirmSave: "編集中の内容は保存されませんが、よろしいですか？",
        },
        applicationConfirm: {
          hierarchy: "申込内容確認",
          title: "申込内容確認",
          send: "送信",
          back: "戻る",
          confirmSave: "編集中の内容は保存されませんが、よろしいですか？",
        },
        applicationComplete: {
          hierarchy: "相談申込完了",
          title: "相談申込完了",
          applicationCompleteStatement: {
            1: "相談申し込みが完了しました。",
            2: "折り返し、サポートセンターよりご連絡させていただきます。",
          },
          backToTop: "Topに戻る",
        },
        online: {
          list: {
            hierarchy: "オンラインカウンセリング一覧",
            title1: "オンラインカウンセリング一覧",
            title2: "新規相談の申込",
            label1: `
              オンライン上でテキストによるご相談ができます。
              <br />
              ご相談内容に応じてピースマインドが最適と思われるカウンセラーを選任し、回答いたします。
              <br />
              ※回答は1つの新規相談につき1回となります。
            `,
            noChoiceStatement:
              "相談回数が上限に達しているため、お申込頂けません。",
            hasCaseInProgressStatement: `
              現在進行中のオンラインカウンセリングが終結するまで、新規のオンラインカウンセリングの相談申込はできません。
              <br />
              お急ぎの方は、下記サポートセンターまでお電話ください。
              <br />
              <br />
              0120-741-489
              <br />
              通話料無料
              <br />
              月～土 9:00～21:00 定休日 日祝／年末年始
            `,
            new: "新規相談する",
            title3: "過去の相談履歴",
            label2: "表示件数",
            listItem1: "返信",
            listItem2: "相談タイトル",
            listItem3: "相談日時",
            listItem4: "相談内容",
            listItem5: "詳細",
            value1: "なし",
            value2: "あり",
            value3: "詳細",
            label3: "全",
            label4: "件",
            back: "戻る",
          },
          detail: {
            hierarchy: "過去の相談履歴",
            title1: "あなたの相談内容",
            label1: "相談日時",
            time1: "(日本時間)",
            title2: "カウンセラーからの回答",
            title3: "担当カウンセラー",
            label2: "回答日時",
            back: "戻る",
          },
          new: {
            hierarchy: "新規相談",
            title1: "新規オンラインカウンセリング",
            item1: "相談タイトル",
            placeholder1: "相談の概要を入力してください",
            item2: "相談内容詳細",
            placeholder2: "相談内容の詳細を記載してください",
            remaining: "残りの記載可能文字数：",
            character: "文字",
            send: "送信",
            back: "戻る",
            modal: {
              confirm: {
                title: "確認",
                label:
                  "ご入力頂いた内容で相談内容を登録します。よろしいですか？",
                ok: "OK",
                cancel: "キャンセル",
              },
              complete: {
                title1: "完了",
                label1: "相談内容の登録が完了しました。",
                label2:
                  "ピースマインドで選任した担当カウンセラーより、相談内容へご回答致します。",
                label3:
                  "お申し込みから2営業日以内に、サポートセンターが、ご相談内容から最適な担当者をお選びします。担当者決定後、ご案内のメールが届き、ご相談開始となりますのでしばらくお待ちください。",
                back: "一覧に戻る",
              },
            },
          },
          course: {
            hierarchy: "利用にあたってのご質問",
            title1: "オンラインカウンセリング",
            title2: "ご相談前の確認事項",
            label1: "■ご利用にあたり、以下の設問にお答えください。",
            label2: "※必須",
            item1: "自殺したいと考えることがありますか",
            item2: "攻撃的な行動を想像したり、したいと感じることがありますか",
            item3: "誰かからの攻撃的行動（DV、ハラスメント等）の相談ですか",
            item4: "アルコールやギャンブルに関する相談ですか",
            item5:
              "子育ての相談の場合、子供に対して攻撃的行動を取ってしまうことがありますか",
            value1: "はい",
            value2: "いいえ",
            confirm: "確認",
            back: "戻る",
          },
          guide: {
            hierarchy: "ご案内",
            title1: "オンラインカウンセリング",
            title2: "ご案内",
            label1:
              "「ご相談前の確認事項」のご回答内容から、お話を丁寧に伺う必要があると考えられます。大変恐縮ですが、まずは下記サポートセンターにお電話いただき、ご相談概要についてお伺いさせてください。",
            label2: "通話料無料",
            label3: "月～土 9:00～21:00 定休日 日祝 / 年末年始",
            label4: `
              生命又は身体の危険がある場合、
              <br />
              まずはお近くの救急医療機関や警察などにご連絡ください。
            `,
            back: "戻る",
          },
        },
      },
    },
    eapSelfCare: {
      selfCheck: {
        hierarchy: "セルフチェック",
        title: "セルフチェック",
        selfCheckStatement: {
          1: "セルフチェックでは、ご自身のコンディションを客観的に知るために役立つチェックがご利用できます。",
        },
        mentalHealth: "こころの健康チェック",
        mentalHealthStatement: {
          1: "こころの健康チェックでは、ストレスの高低だけでなく、ストレス要因についても知ることができます。また、受診したセルフチェックの履歴はいつでも振り返ることができますので、あなたの「こころの健康」のために、ぜひ定期的にご利用ください。",
        },
        checkStart: "チェックを受ける",
        checkHistory: "チェック履歴を見る",
        mentalHealthCheck: {
          hierarchy: "こころの健康チェック",
          title: "こころの健康チェック",
          stepInfo: {
            1: "1.基本情報の入力 ",
            2: " 2.設問への回答 ",
            3: " 3.結果発表",
          },
          basicInformation: {
            introduction: `
              50問の設問に回答し、あなたの「こころの健康」の状態をチェックします。
              <br />
              はじめに基本情報を入力して、受験を開始してください。
            `,
            headerTitle: "基本情報の入力",
            sex: "性別",
            age: "年齢",
            toEditProfile: `※性別・年齢の修正は <a href="/user/edit">プロフィール編集</a> で行ってください`,
            job: "職位",
            exerciseDay: "運動をする日",
            hobbyDay: "趣味や楽しみに接する日",
            overtimeWork: "最近１ヶ月の時間外労働時間",
            performance:
              "最近の仕事や日常生活におけるパフォーマンスの状態を表すと",
            performanceNote: "※10点を最良とした場合の現在値",
            placeholder: "選択してください",
            sendMailStatement: {
              1: `定期的なセルフケアのために、今回の結果をメールで受け取ることができます。(7日後)
                <br />
                ※７日以内に再度受診した場合、先に受検した分のメールは送信されません。`,
              2: "メールを受信する",
            },
            checkStart: "受診する",
            back: "戻る",
          },
          survey: {
            sessionTimeout:
              "セッションが切れました。前画面より再度回答をお願いします",
            close: "閉じる",
            introduction: `
              <b><u>各設問の当てはまる箇所にチェックを入れてください</u></b>
              <br />
              チェックは全部で50問あります。問1~10に回答して次へボタンを押してください。
              <br />
              50問のうち「どちらともいえない」の回答数が20個を超えないようにしてください。
            `,
            question: "設問",
            applicable: "あてはまる",
            neither: "どちらともいえない",
            notApplicable: "あてはまらない",
            neitherStatement: {
              1: "現在の「どちらともいえない」の回答数は「",
              2: "」です。",
            },
            countNeitherError:
              "「どちらともいえない」の回答数が20を超えています。",
            unansweredError:
              "未入力の設問があります。全ての設問にお答えください。",
            next: "次へ",
            back: "戻る",
          },
          result: {
            noResult: "閲覧できる結果が存在しません。",
            headerTitleResult: "こころの健康チェック結果",
            testedAt: "受診日時(日本時間)",
            headerTitleStressProfile: "ストレスプロフィール",
            stressProfileStatement:
              "今回のチェック結果から、あなたのストレス要因を分析した結果を表しています。",
            titleNegative: "マイナス思考度",
            negativeStatement:
              "自信の程度や、物事をマイナスにとらえる程度を示します",
            titleSupport: "サポート不足度",
            supportStatement:
              "周囲環境によるサポートや、回復力の程度を示します",
            titleUnstable: "情緒不安定度",
            unstableStatement: "情緒の過敏性と、安定度を示します",
            titleDistrust: "社会不信度",
            distrustStatement:
              "周囲への信頼感と、心理的な閉塞傾向の程度を示します",
            titleEnergy: "エネルギー不足度",
            energyStatement:
              "行動するために必要な基礎的エネルギーの程度を示します",
            raderChartStatement:
              "グラフが広がっているほど、ストレスが高い状態を表します。",
            aboutNegative: "マイナス思考度について",
            aboutSupport: "サポート不足度について",
            aboutUnstable: "情緒不安定度について",
            aboutDistrust: "社会不信度について",
            aboutEnergy: "エネルギー不足度について",
            thisTime: "今回",
            lastTime: "前回",
            headerTitleComprehensiveEvaluation: "総合評価",
            stressIndex: "ストレス指数",
            stressIndexStatement:
              "ストレス指数は、性別・年齢別に評価されており、各年代の平均を50とした時の値です。",
            stressIndexComment: "総合コメント",
            headerTitleCounseling: "カウンセリングについて",
            counselingStatement:
              "職場のストレスやプライベートでお悩みがある方は、カウンセリングサービスをご利用ください。お悩みの整理から問題解決に向けたアクションまでカウンセラーがサポートします。",
            reserveButton: "カウンセリングを予約する",
            checkHistory: "チェック履歴を見る",
            selfLeanings: {
              title: "かんたんメントレ講座",
              description:
                "　経済状況や組織改編等、私たちを取り巻く環境は常に変化しています。そのような状況の中で、私たちには変化への対応を求められており、不安や心身の負担による大きなストレスを避けることはできません。そうした現状の中で、より豊かなワークライフを過ごすためには、まずストレスを自分自身が知り、マネジメントしていくことが重要になってきます。かんたんメントレで、ストレス状況を把握し、ストレス対処の方法を身につけていただきたいと思います。",
              link: "セルフラーニングへ",
            },
          },
          history: {
            hierarchy: "こころの健康チェック履歴",
            title: "こころの健康チェック履歴",
            description:
              "結果を見るボタンをクリックすると、その回の結果が表示されます。",
            noData: "受診履歴がありませんでした。",
            surveyDate: "受診日時(日本時間)",
            stressIndex: "ストレス指数",
            negativeLevel: "マイナス思考度",
            supportLevel: "サポート不足度",
            unstableLevel: "情緒不安定度",
            distrustLevel: "社会不信度",
            energyLevel: "エネルギー不足度",
            resultDetail: "チェック結果詳細",
            buttonResultDetail: "結果を見る",
            back: "戻る",
          },
        },
      },
    },
    inquiry: {
      conplete: {
        title: "お問い合わせ送信完了",
        message: "お問い合わせを承りました。",
        backToTop: "トップに戻る",
      },
      confirm: {
        title: "お問い合わせ内容確認",
        fullName: "氏名",
        fullNameKana: "氏名ふりがな",
        inquiryMethod: "ご連絡方法",
        mailAddress: "メール",
        phone: "電話",
        other: "その他",
        requestForContact: "ご連絡に関してのご希望",
        contents: "お問い合わせ内容",
        contentsDetail: "お問い合わせの具体的内容",
        userPolicyDescription: {
          1: "当社の",
          2: "に同意する",
        },
        userPolicy: "個人情報の取扱いについて",
        sendButton: "送信",
        backButton: "キャンセル",
        confirmSave: "編集中の内容は保存されませんが、よろしいですか？",
      },
      new: {
        title: "お問い合わせフォーム",
        fullName: "氏名",
        lastName: "性",
        firstName: "名",
        fullNameKana: "氏名ふりがな",
        lastNameKana: "せい",
        firstNameKana: "めい",
        inquiryMethod: "ご連絡方法",
        mailAddress: "メール",
        phone: "電話",
        other: "その他",
        requestForContact: "ご連絡に関してのご希望",
        contents: "お問い合わせ内容",
        contentsDetail: "お問い合わせの具体的内容",
        userPolicyDescription: {
          1: "当社の",
          2: "に同意する",
        },
        userPolicy: "個人情報の扱いについて",
        confirmButton: "確認",
        confirmSave: "編集中の内容は保存されませんが、よろしいですか？",
      },
    },
    passwordReminder: {
      passwordReminder: {
        completeModal: {
          messageRow: {
            1: "入力されたアドレスにパスワード再設定用URLを送信しました。",
            2: "メール内のURLにアクセスし、パスワードを再設定してください。",
            3: "なお、URLの有効期限は発行から24時間です。",
          },
          okButton: "OK",
        },
        title: "パスワードリマインダー",
        useService: "利用サービス",
        stressCheck: {
          name: "ストレスチェック",
          loginId: "個人ID",
        },
        eap: {
          name: "EAP",
          loginId: "メールアドレス",
        },
        invalidLoginId: "個人IDが異なるため照合できません。",
        notExistsSecretQuestionAnswer: {
          messageRow: {
            1: "初回のパスワード設定が完了していないため、本機能が使用できません。",
            2: "ご所属の会社・団体からの通知、メール等で案内された初期パスワードをお使い頂き、ログインをお願いいたします。",
          },
        },
        invalidMailAddress: "登録されているメールアドレスと異なります。",
        descriptionStressCheck:
          "※個人IDはご所属の会社・団体から案内されたものを使用してください。",
        descriptionEap:
          "※メールアドレスは、EAPサービスの利用登録時に設定したものを入力してください。",
        okButton: "OK",
      },
      passwordReminderEmail: {
        completeModal: {
          messageRow: {
            1: "入力されたアドレスにパスワード再設定用URLを送信しました。",
            2: "メール内のURLにアクセスし、パスワードを再設定してください。",
            3: "なお、URLの有効期限は発行から24時間です。",
          },
          okButton: "OK",
        },
        title: "メールアドレス入力",
        mailAddress: "メールアドレス",
        invalidMailAddress: "登録されているメールアドレスと異なります。",
        description:
          "※メールアドレスは会社・団体から案内されたものを入力してください。",
        okButton: "OK",
      },
      passwordReminderPassword: {
        completeModal: {
          message: "パスワードを変更しました。",
          loginButton: "OK",
        },
        title: "パスワード設定",
        newPassword: "新しいパスワード",
        description: {
          messageRow: {
            1: "パスワードは12文字以上かつ次の4つのグループの中より3種類の半角文字を使用してください。",
            2: "1) アルファベット大文字（AからZ）",
            3: "2) アルファベット小文字（aからz）",
            4: "3) 数字（0から9）",
            5: '4) 記号（ハイフン"-"、アンダーバー"_"のみ）',
          },
        },
        updateButton: "OK",
        invalidCharactorType:
          '半角英大文字、英小文字、数字、記号（ハイフン"-"、アンダーバー"_"のみ）のうち3種類以上を利用してください',
        invalidLengthPassword: "12文字以上255文字以下にしてください",
        differentPassword:
          "「現在のパスワード」と異なるパスワードを設定してください",
      },
      passwordReminderSecretQuestion: {
        title: "秘密の質問回答",
        secretQuestion: "秘密の質問",
        secretQuestionAnswer: {
          row: {
            1: "秘密の質問回答",
            2: "",
          },
          placeholder: "秘密の質問回答",
        },
        invalidAnswer: "秘密の質問回答が正しく有りません。",
        okButton: "OK",
      },
    },
    user: {
      account: {
        password: {
          completeModal: {
            title: "パスワード 更新完了",
            message: "完了しました",
            okButton: "OK",
          },
          title: "パスワード変更",
          currentPassword: "現在のパスワード",
          newPassword: "新しいパスワード",
          invalidCharactorType:
            '半角英大文字、英小文字、数字、記号（ハイフン"-"、アンダーバー"_"のみ）のうち3種類以上を利用してください',
          invalidLengthPassword: "12文字以上255文字以下にしてください",
          invalidCurrentPassword:
            "入力された現在のパスワードが間違っています。",
          differentPassword:
            "「現在のパスワード」と異なるパスワードを設定してください",
          okButton: "OK",
          description: {
            messageRow: {
              1: "パスワードは12文字以上かつ次の4つのグループの中より3種類の半角文字を使用してください。",
              2: "1) アルファベット大文字（AからZ）",
              3: "2) アルファベット小文字（aからz）",
              4: "3) 数字（0から9）",
              5: '4) 記号（ハイフン"-"、アンダーバー"_"のみ）',
            },
          },
        },
        twoFactorAuthentication: {
          title: "二要素認証",
          reset: "再設定する",
          sms: {
            statement: {
              1: "SMSによる二要素認証が有効です。",
            },
          },
          totp: {
            title: "二要素認証",
            statement: {
              1: "認証アプリによる二要素認証が有効です。",
              2: "認証アプリをインストールしたスマートフォンなどにアクセスできなくなった場合、リカバリーコードを使って認証することができます。",
              3: "このテキストコードを安全な場所に保存してください。",
            },
            openCodeLink: "リカバリーコードを表示",
            closeCodeLink: "リカバリーコードを非表示",
            used: "使用済み",
          },
        },
      },
      mailAddress: {
        completeModal: {
          messageRow: {
            1: "入力されたアドレスに変更用URLを送信しました。",
            2: "メール内のURLにアクセスし、メールアドレス変更を完了してください。",
            3: "なお、URLの有効期限は発行から30分です。",
          },
          okButton: "OK",
        },
        title: "メールアドレス変更",
        currentMailAddress: "現在のメールアドレス",
        newMailAddress: "新しいメールアドレス",
        newMailAddressPlaceholder: "sample{'@'}mail.com",
        errorMessage:
          "入力されたメールアドレスが正しくないか、既に使用されています。",
        okButton: "OK",
        reSendButton: "再送信",
        description: {
          messageRow: {
            1: "ご利用キャリアのメールソフトによっては、EAPシステムからのメールが、迷惑メールへ分類されている可能性や、受信拒否設定がされている可能性があります。メールが届かない場合は迷惑メールフォルダおよび設定をご確認ください。",
          },
        },
      },
      mailAddressComplete: {
        complete: {
          title: "メールアドレス変更完了",
          statement: {
            1: "メールアドレスの変更が完了しました。",
            2: "ユーザー情報が変更されたため、再度ログインを実施してください。",
          },
          movePage: "ログイン画面へ",
        },
        error: {
          title: "メールアドレス変更",
          statement: {
            1: "メールアドレスの変更に失敗しました。",
            2: "URLの有効期限が切れている可能性があります。",
            3: "再度メールアドレスの変更手続きを行ってください。",
          },
          okButton: "OK",
        },
      },
      secretQuestion: {
        title: "秘密の質問設定",
        secretQuestion: "秘密の質問",
        answer: "回答",
        secretQuestionAnswer: "秘密の質問回答",
        okButton: "OK",
        description: {
          messageRow: {
            1: "※秘密の質問は、お客様がログインする際にパスワードを忘れてしまった場合に使用します。",
            2: "複数回秘密の質問に対する回答を設定された場合、最後に登録された質問が適用されます。",
            3: "また、通常通りご利用いただく場合には秘密の質問をお尋ねすることはなく、個人IDとパスワードを入力して本人認証を行ないます。",
          },
        },
        completeModal: {
          title: "更新完了",
          message: "完了しました",
          okButton: "OK",
        },
      },
      userTop: {
        troubleUp: "今、お悩みを抱えて",
        troubleUnder: "いませんか？",
        consultationApplication: "カウンセリング申込はこちら",
        noticeList: "お知らせ一覧",
      },
      mailAddressAuthentication: {
        title: "会員登録メールアドレス入力",
        statement: {
          1: "カウンセリングサービスの会員登録となります。ストレスチェックの会員登録ではないためご注意ください。",
          2: "ご登録するメールアドレスを入力のうえ、送信してください。その後ご入力いただいたメールアドレスに届く会員登録フォームよりご登録をお願いいたします。",
        },
        note: {
          title: "＊注意事項＊",
          1: "会員登録には携帯電話番号の登録が必須となっております。携帯電話をお持ちでない方はEAPシステムの利用が出来ませんので予めご了承ください。",
          2: "お送りしたメールに記載されているURLの有効期限は30分となっております。30分を経過しても会員登録がされない場合は無効となりますのでご了承ください。",
          3: "ご利用キャリアのメールソフトによっては、EAPシステムからのメールが、迷惑メールへ分類されている可能性や、受信拒否設定がされている可能性があります。メールが届かない場合は迷惑メールフォルダおよび設定をご確認ください。",
        },
        mailAddress: "メールアドレス",
        mailAddressConfirm: "メールアドレス(確認用)",
        invalidMailAddress: "入力されたメールアドレスは既に登録されています。",
        unusableMailAddress: "入力されたメールアドレスはご利用いただけません。",
        send: "送信する",
      },
      mailAddressAuthenticationComplete: {
        title: "メール送信完了",
        statement: {
          1: {
            1: "ご登録いただきましたメールアドレス(",
            2: ")宛に、登録用URLを記載した確認メールを送信いたしました。",
          },
          2: "内容をご確認の上、ご登録をお願いします。登録用メールが届くまで1分程度かかる場合があります。",
          3: "メールが届かない場合は、以下から再送信できます。",
        },
        resend: "メール再送信",
        resendMessage: "本登録URL記載のメールを再送しました。",
        backToTop: "Topに戻る",
      },
      new: {
        title: "プロフィール入力",
        statement: "以下の項目をすべて入力してください。",
        caution: `
          ※過去に電話などで弊社サービスを利用されたことがある場合、<br />
          その際にご共有いただいた一部登録情報につきましては入力欄内に初期表示しています。<br />
          変更などがお有りの場合は編集いただき、他の項目もご入力の上、確認画面へとお進みください。
        `,
        password: "パスワード",
        passwordConfirm: "パスワード(確認用)",
        passwordPlaceholder: "パスワード",
        lastName: "姓",
        lastNamePlaceholder: "姓",
        firstName: "名",
        firstNamePlaceholder: "名",
        lastNameKana: "姓（かな）",
        lastNameKanaPlaceholder: "せい",
        firstNameKana: "名（かな）",
        firstNameKanaPlaceholder: "めい",
        sex: "性別",
        otherSexPlaceholder: "その他の場合に入力してください",
        birthday: "生年月日",
        birthdayPlaceholder: "1990/01/01",
        birthdayYearPlaceholder: "1990",
        birthdayMonthPlaceholder: "1",
        birthdayDayPlaceholder: "1",
        mobilePhoneNumber: "携帯電話番号",
        countryCodePlaceholder: "選択して下さい",
        mobilePhoneNumberPlaceholder:
          "携帯電話番号をハイフンなしで入力してください(例：09012345678)",
        prefecture: "お住まいの都道府県",
        prefecturePlaceholder: "選択して下さい",
        address: "市町村",
        addressPlaceholder: "市町村を入力してください",
        employeeFamily: "対象者",
        sendMailMagazine: "メールマガジン",
        sendMailMagazineStatement: "メールマガジンの配信を",
        userPolicyStatement: {
          1: "当社の",
          2: "に同意する",
        },
        userPolicy: {
          1: "利用規約",
          2: "・",
          3: "個人情報の取扱いについて",
        },
        useDataAgreementStatement: {
          1: "当社では、サービス品質向上のため、お客様からご提供いただいた情報を、下記方針の基で匿名加工などを行った上で第三者提供を行うことがあります。",
          2: "に同意する",
        },
        useDataAgreement: "データの第三者提供",
        description: {
          messageRow: {
            1: "パスワードは12文字以上かつ次の4つのグループの中より3種類の半角文字を使用してください。",
            2: "1) アルファベット大文字（AからZ）",
            3: "2) アルファベット小文字（aからz）",
            4: "3) 数字（0から9）",
            5: '4) 記号（ハイフン"-"、アンダーバー"_"のみ）',
          },
        },
        confirm: "確認",
        confirmSave: "編集中の内容は保存されませんが、よろしいですか？",
      },
      newConfirm: {
        title: "入力内容確認",
        send: "送信",
        back: "戻る",
        confirmSave: "編集中の内容は保存されませんが、よろしいですか？",
        invalidCharactorType:
          '半角英大文字、英小文字、数字、記号（ハイフン"-"、アンダーバー"_"のみ）のうち3種類以上を利用してください',
        invalidLengthPassword: "12文字以上255文字以下にしてください",
      },
      newComplete: {
        title: "会員登録完了",
        statement: {
          1: "会員登録が完了致しました。",
          2: "下記リンクより、EAPシステムへログインしてください。",
        },
        login: "個人ログインへ",
      },
      twoFactorAuthenticationMethod: {
        title: "二要素認証の設定",
        statement:
          "IDとパスワードに加え、SMSや認証アプリを使って本人確認をすることで、アカウントのセキュリティを高めることができます。",
        sms: {
          label: "SMSを利用する",
          description: {
            1: "携帯電話に送信されたSMSにかかれている認証番号を入力して認証します。",
            2: "※日本国外の携帯電話番号をお使いの場合、一部の国でSMSを受け取れないことがあります。",
          },
        },
        totp: {
          label: "認証アプリを利用する",
          description:
            "スマートフォンなどにインストールした認証アプリに表示される認証番号を入力して認証をします。",
        },
        application: "次のような認証アプリの利用をおすすめします。",
        nextButton: "次へ",
      },
      twoFactorAuthentication: {
        title: "SMSによる二要素認証の設定",
        send: "SMSに認証番号を送信",
        cancel: "キャンセル",
        statement: {
          0: "「＋",
          1: "」宛にSMSを送信いたしました。",
          2: `5分以内にメッセージ内に記載された6桁の数字を入力して下さい。
                          <br />
                          SMSが届かない場合は携帯電話番号が間違っている可能性がありますので、
                          <br />
                          「携帯電話番号を入力し直す」ボタンより再度携帯電話番号を入力して再送してください。`,
          3: `本人確認のため、携帯電話のSMS(ショートメッセージサービス)を利用して認証を行います。
                          <br />
                          携帯電話番号が他のお客様に公開されることはありません。`,
        },
        twoFactorAuthenticationNumberPlaceholder: "6桁の数字を入力してください",
        countryCodePlaceholder: "選択して下さい",
        mobilePhoneNumberPlaceholder:
          "携帯電話番号をハイフンなしで入力してください",
        invalidSmsNumber: `無効な番号のため認証できませんでした。
                        <br />
                        携帯電話番号が間違っている、または有効期限が切れている可能性があります。`,
        authenticate: "認証する",
        changeMobilePhoneNumber: "携帯電話番号を入力し直す",
        totpLink: {
          1: `※日本国外の電話番号をお使いの場合、一部の国でSMSを受け取れないことがあります。
              <br />
              SMSが届かない場合は、`,
          2: "アプリでの二要素認証",
          3: "をご利用ください。",
        },
      },
      twoFactorAuthenticationComplete: {
        title: "二要素認証完了",
        statement: "二要素認証が完了致しました",
        ok: "OK",
      },
      twoFactorAuthenticationTotp: {
        title: "認証アプリによる二要素認証の設定",
        statement1: {
          1: "下の画像をスマホの二要素認証アプリで読み取ってください。QRコードが使用できない場合は、代わりに",
          2: "こちらのテキストコード",
          3: "を入力してください。",
        },
        statement2:
          "認証アプリでQRコード画像を読み取ると、アプリにコードが表示されますので、以下に入力してください。",
        invalidOneTimePassword: "無効な番号のため認証できませんでした。",
        nextButton: "次へ",
        cancelButton: "キャンセル",
        textCodeModal: {
          title: "テキストコード",
          statement: {
            1: "認証アプリに以下のテキストコードを入力してください。",
            2: "このコードは他人に見せないでください。",
          },
          closeButton: "閉じる",
        },
      },
      twoFactorAuthenticationTotpComplete: {
        title: "リカバリーコードの保存",
        statement: {
          1: "認証アプリをインストールしたスマートフォンなどにアクセスできなくなった場合、リカバリーコードを使って認証することができます。",
          2: "以下のテキストコードを安全な場所に保存してください。",
        },
        completeButton: "設定を完了する",
      },
      stressCheckConnection: {
        title: "ストレスチェック情報連携",
        statement: {
          1: "ストレスチェック情報を連携しますか？",
          2: "連携を希望しない方、これまでストレスチェックを受検したことが無い方は、「連携しない」ボタンを押してください。",
          3: "ストレスチェックのIDとパスワードを入力の上、「送信」ボタンを押下して下さい。",
          4: "ストレスチェックへログインしてください。",
        },
        merit: {
          1: "「連携する」ボタンを押すと、ログイン後の画面でストレスチェック・カウンセリング両メニューが表示され、利用できるようになります。",
          2: "また次回以降のログインの際、ログイン画面で「ストレスチェック」「カウンセリング」のどちらを選んでログインしても、上記の通り両メニューが表示されるようになります。",
        },
        scLoginId: "ストレスチェック 個人ID",
        scLoginIdPlaceholder: "ストレスチェック 個人ID",
        scLoginPassword: "ストレスチェック パスワード",
        scLoginPasswordPlaceholder: "ストレスチェック パスワード",
        invalidScUser: `ストレスチェックと連携できませんでした。
                          <br />
                          ストレスチェック ログインID または パスワードが間違っている可能性があります。`,
        noScHistory: `ストレスチェックが未実施です。ログイン画面よりストレスチェックにログインし、ストレスチェックを実施後に再度連携をして頂くか、前画面で連携しないを選択してください。`,
        duplicatedScUser: `既に別アカウントで連携済みのユーザーのため連携できません。`,
        agree: "連携する",
        disagree: "連携しない",
        send: "送信",
        back: "戻る",
        login: "ログイン",
      },
      stressCheckConnectionComplete: {
        title: "ストレスチェック連携完了",
        statement: "ストレスチェック連携が完了致しました",
        ok: "OK",
      },
      edit: {
        title: "プロフィール編集",
        statement: "進行中の相談が完了するまでプロフィールを編集できません。",
        migrationUserStatement:
          "サービスのご利用に必要な情報が一部変更になりました。下記の項目をご入力ください。",
        mailAddress: "メールアドレス",
        mailAddressPlaceholder: "メールアドレスを入力してください",
        lastName: "姓",
        lastNamePlaceholder: "姓",
        firstName: "名",
        firstNamePlaceholder: "名",
        lastNameKana: "姓（かな）",
        lastNameKanaPlaceholder: "せい",
        firstNameKana: "名（かな）",
        firstNameKanaPlaceholder: "めい",
        sex: "性別",
        otherSexPlaceholder: "その他の場合に入力してください",
        birthday: "生年月日",
        birthdayPlaceholder: "1990/01/01",
        birthdayYearPlaceholder: "1990",
        birthdayMonthPlaceholder: "1",
        birthdayDayPlaceholder: "1",
        mobilePhoneNumber: "携帯電話番号",
        countryCodePlaceholder: "選択して下さい",
        mobilePhoneNumberPlaceholder:
          "携帯電話番号をハイフンなしで入力してください(例：09012345678)",
        prefecture: "お住まいの都道府県",
        prefecturePlaceholder: "選択して下さい",
        address: "市町村",
        addressPlaceholder: "市町村を入力してください",
        employeeFamily: "対象者",
        sendMailMagazine: "メールマガジン",
        sendMailMagazineStatement: "メールマガジンの配信を",
        userPolicyStatement: {
          1: "当社の",
          2: "に同意する",
        },
        userPolicy: {
          1: "利用規約",
          2: "・",
          3: "個人情報の取扱いについて",
        },
        useDataAgreementStatement: {
          1: "当社では、サービス品質向上のため、お客様からご提供いただいた情報を、下記方針の基で匿名加工などを行った上で第三者提供を行うことがあります。",
          2: "に同意する",
        },
        useDataAgreement: "データの第三者提供",
        update: "更新",
        confirm: {
          changeMobilePhoneNumber: {
            title: "プロフィールを更新してもよいですか？",
            statement:
              "電話番号を変更すると、次の画面で二要素認証の再設定が必要です。",
            ok: "更新する",
            cancel: "キャンセル",
          },
        },
      },
      editComplete: {
        title: "プロフィール編集完了",
        statement: "プロフィール編集が完了致しました",
        ok: "OK",
      },
      mailReceivingChange: {
        title: "メール受信設定",
        useMail: {
          subject: "利用メール",
          description: {
            1: "サービスをご利用いただくために必要なメールです。",
            2: "このメールは受信停止をすることはできません。",
          },
        },
        mailMagazine: {
          subject: "メールマガジン",
          description: "メンタルヘルスに関するトピックをお届けするメールです。",
        },
        questionnaireMail: {
          subject: "アンケートメール",
          description: "当社での研究を目的としたアンケートに関するメールです。",
        },
        effectMeasurementMail: {
          subject: "カウンセリングサーベイメール",
          description:
            "カウンセリングの利用前後の状態を計測するためのメールです。",
        },
        saveButton: "保存",
        completedModal: {
          title: "更新完了",
          message: "メール受信設定を更新しました。",
          button: "OK",
        },
      },
    },
    logout: {
      logoutMessage: "ログアウトしました。",
      sendTopPageButton: "取引先トップへ",
    },
    howToUseThisPlatform: {
      hierarchy: "利用案内",
      tabName: {
        sc: "ストレスチェック",
        eap: "カウンセリング",
      },
      counseling: {
        tabName: "カウンセリング",
        title: "カウンセリングサービス 利用ガイド",
        statement: {
          1: "カウンセリングサービスのご利用についてのガイドをご覧いただけます。合わせて",
          2: "よくあるご質問",
          3: "のページもご活用ください。",
        },
      },
    },
    qa: {
      modal: {
        title: "よくあるご質問",
        closeButton: "閉じる",
      },
      title: "よくあるご質問",
      category: "カテゴリ",
      keyword: "キーワード",
      searchButton: "検索",
      searchCancelButton: "検索解除",
      stressCheck: "ストレスチェック",
      eap: "EAP",
      hasDataError: "対象がありません",
      hierarchy: {
        display: "よくあるご質問",
      },
    },
    wos: {
      questionnaire: {
        title: "アンケートにお答えください",
        inputError: `未入力の項目があります</br>「設問1」が入力されていません`,
        statement: {
          top: {
            1: "このアンケートは、現在お勤め中で長期休業中ではない方を対象に、EAPサービスの効果を測る目的で実施しています。",
            pre: "カウンセリングを受ける前の状態をお答えください。",
            post: "カウンセリング実施後の状態をお答えください。",
          },
          bottom:
            "ご回答いただいた内容は個人が特定できない統計データとして、研究発表や年間報告書等に反映させていただく場合がございます。あらかじめご了承ください。"
        },
        nextButton: "次へ",
        backButton: "戻る",
        sendButton: "アンケート送信",
        question: {
          q: "問",
          workingStatusC: "：過去30日の間に、1日でも勤務しましたか？",
          problemSolvingLevelC:
            "：あなたの元々持っていたお悩みを10として、現在そのお悩みはどの程度ですか。1から10でお答えください。",
          satisfactionC: "：コンサルタントの対応に満足していますか",
          recentStatus:
            "：最近1か月間のあなたの状態についてうかがいます。最もあてはまるものを選んでください。",
          lifeSatisfactionC: ": 自分の人生は上手くいっていると思う",
          approachToWorkC: ": 仕事に行くのが楽しみだ",
          presentismC: ": 仕事に集中するのが難しかった",
          painAtWorkC: ": 仕事に行くと思うとうんざりする",
          absentismC:
            "：最近1か月の間に、ご相談の問題が影響して仕事を休んだ時間はどれくらいですか？遅刻・早退も含めた合計時間で、最もあてはまるものを選んでください。",
          jobPerformanceC: `：次の0から10点までの数字は、仕事の出来（でき）を表したものです。<br />
            0点は、あなたの仕事を他の誰かがやって最悪だった時の出来、10点は一番仕事の出来る人がやった場合の出来とします。<br />
            <br />
            最近一か月の、あなたの全般的な仕事の出来は何点くらいになるでしょうか。もっとも当てはまる数字を選んでください。`,
        },
        answer: {
          workingStatusC: {
            yes: "はい",
            no: "いいえ - 休職中です/仕事をしていません",
          },
          problemSolvingLevelC: {
            lightened: "軽くなった",
            same: "変わらない",
          },
          jobPerformanceC: {
            worst: "最悪",
            top: "最高",
          },
        },
        errors: {
          1: "未入力の項目があります",
          2: "が入力されていません",
        },
        complete: {
          title: "アンケート送信完了",
          statement: "ご協力ありがとうございました。",
          closeButton: "閉じる",
        },
        periodEnd: {
          statement: "このアンケートは回答期間が終了しています。",
          closeButton: "閉じる",
        },
        answered: {
          statement: "このアンケートは回答済みです。",
          closeButton: "閉じる",
        },
      },
      mailStop: {
        title: "メール受信設定",
        settingItem: "カウンセリングサーベイメール",
        description:
          "カウンセリングの利用前後の状態を計測するためのメールです。",
        saveButton: "保存",
        complete: {
          title: "メール受信設定変更完了",
          statement: {
            on: "カウンセリングサーベイメールを「受信する」に変更しました。",
            off: "カウンセリングサーベイメールを「受信しない」に変更しました。",
          },
          closeButton: "閉じる",
        },
      },
    },
  },
};
